
import { nextTick, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import NavBar from "@/components/navBar.vue";
import { Icon } from "vant";
import { orderNotify } from "@/api/request.js";
export default {
  name: "paysuccess",
  components: {
    navbar: NavBar,
    [Icon.name]: Icon,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const payname = ref("微信支付");
    const price = ref(route.query.price);
    const type: any = ref(route.query.type);
    const wx: any = ref(route.query.wx);
    if (type.value == 7) {
      payname.value = "微信支付";
    } else if (type.value == 6) {
      payname.value = "云币支付";
    }
    const codeuri =
      "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzk0NjIyNDU1Mw==&scene=110#wechat_redirect";
   
    //订单测试
    const getorderNotify = async () => {
      const data = await orderNotify();
      console.log(data);
    };
    getorderNotify();
    const onClickLeft = () => {
      // if ((route.query.show as any) == 1) {
        // router.push({ path: "/purchase/number" });
        window.location.href='https://centrex.av5g.cn/'
      // } else if ((route.query.show as any) == 2) {
      //   router.push({
      //     path: "/workbench/yunbi",
      //     query: { cid: route.query.cid },
      //   });
      // }
    };
    return { payname, price, onClickLeft, wx };
  },
};
